.enemy {
  display: block;
  position: relative;

  transition: transform 0.25s;
  will-change: transform;

  img {
    display: block;
    width: 100%;
  }

  h4 {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 2.125rem;
    left: 3.5rem;

    width: calc(100% - 7rem);
    height: 5rem;
    margin: 0;

    font-size: 1.75rem;
    font-family: var(--font-caption);
    font-weight: 400;
    line-height: 1;

    text-transform: uppercase;
    text-align: center;

    @media screen and (max-width: 479px) {
      font-size: 6vw;
      height: 14vw;
    }
  }

  &.is-opened:hover {
    @media (hover: hover) {
      transform: scale(1.075);
    }
  }
}