.final {
  display: block;
  position: relative;
  z-index: 2;

  width: var(--width-site);
  max-width: calc(100% - 3rem);
  margin: 0 auto;
  padding: 1.5rem 0 2.5rem;

  .final-header {
    width: 100%;
    max-width: 100%;
    margin: 0 auto 1rem;

    @media screen and (max-width: 1023px) {
      width: var(--width-content);
      margin-bottom: 2rem;
    }
  }

  .final-title {
    width: 100%;
    max-width: 100%;

    h2 {
      display: block;
      margin: 0;

      font-size: 3.5rem;
      font-weight: 700;
      font-family: var(--font-caption);
      text-transform: uppercase;

      @media screen and (max-width: 1023px) {
        font-size: 3rem;
      }

      @media screen and (max-width: 479px) {
        font-size: 2.5rem;
      }

      @media screen and (max-width: 359px) {
        font-size: 2rem;
      }
    }

    p {
      display: block;
      max-width: 50rem;
      margin: 1rem 0;

      font-size: 2.5rem;
      font-weight: 500;
      line-height: 1.25;

      @media screen and (max-width: 1023px) {
        font-size: 2rem;
      }

      @media screen and (max-width: 479px) {
        font-size: 1.5rem;
      }

      @media screen and (max-width: 359px) {
        font-size: 1.25rem;
      }
    }

    @media screen and (max-width: 1023px) {
      width: var(--width-content);
      margin: 0 auto 2rem;
    }
  }

  .final-grid {
    width: 100%;
    max-width: 100%;
    margin: 2rem auto;

    @media screen and (max-width: 1023px) {
      width: var(--width-content);
      margin: 0 auto 1rem;
    }
  }
}