@mixin gradient-border {
  &::after {
    content: '';

    display: block;
    position: absolute;
    z-index: -2;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    opacity: 0.5;

    background: var(--gradient-border);
    border-radius: inherit;
  }

  &::before {
    content: '';

    display: block;
    position: absolute;
    z-index: -1;

    top: 1px;
    left: 1px;

    width: calc(100% - 2px);
    height: calc(100% - 2px);

    background-image: var(--gradient-forms);
    border-radius: inherit;
  }
}