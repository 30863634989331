.button {
  display: flex;
  align-items: center;
  justify-content: center;

  user-select: none;
  cursor: pointer;

  height: 4.25rem;
  padding: 0 2.5rem;

  font-size: 1.25rem;
  font-weight: 400;
  font-family: var(--font-general);

  color: var(--color-black);
  background-color: var(--color-links);

  border: 0;
  border-radius: 2rem;

  transition: background-color 0.25s;

  &[disabled] {
    pointer-events: none;
    opacity: 0.5;
  }

  &:hover {
    background-color: var(--color-accent);
  }

  &[data-loading] {
    pointer-events: none;

    background-color: var(--color-accent);
    background-image: var(--gradient-loading);
    background-size: 50px 50px;

    animation: loading-stripes 1.5s linear infinite;
  }

  @media screen and (max-width: 479px) {
    height: 4rem;
    font-size: 1rem;
  }

  @media screen and (max-width: 359px) {
    height: 3.25rem;
  }
}