.opposing {
  display: block;
  position: relative;
  z-index: 2;

  width: 100%;
  margin: 6.25rem auto 0;

  .opposing-start {
    max-width: var(--width-max);
  }

  .opposing-helper {
    display: none;
    align-items: center;
    justify-content: center;
    position: relative;

    height: 2rem;
    margin: 0;

    font-size: 0.875rem;
    text-align: center;

    color: var(--color-white);

    &::after {
      content: '';

      position: relative;

      width: 2rem;
      height: 2rem;
      margin-left: 0.5rem;

      background-image: url('/images/decors/tap.png');
      background-repeat: no-repeat;
      background-size: contain;

      animation: pulse 2s infinite linear;
    }

    @media screen and (max-width: 1023px) {
      display: flex;
    }
  }

  .opposing-slider {
    position: relative;
    user-select: none;

    margin: 1rem 0 -1.5rem;
    padding: 1.5rem 5vw;

    background: var(--gradient-slider);

    &::after {
      content: '';

      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;

      height: 100%;
      width: 6rem;

      background: var(--gradient-right);

      @media screen and (max-width: 1023px) {
        display: none;
      }
    }

    @media screen and (max-width: 1023px) {
      margin: 0 0 1rem;
      padding-bottom: 6rem;
    }

    @media screen and (max-width: 479px) {
      padding-bottom: 4.5rem;
    }
  }

  .opposing-pagination {
    display: none;
    align-items: center;
    justify-content: center;

    position: absolute;
    bottom: 0;

    width: 100%;
    height: 4rem;
    margin: 0;

    color: var(--color-darkgray);

    strong {
      display: block;

      font-size: 1.625rem;
      font-weight: 300;

      color: var(--color-text);
    }

    span {
      display: block;
      transform: translateY(0.25rem);
    }

    @media screen and (max-width: 1023px) {
      display: flex;
    }
  }

  @media screen and (max-width: 767px) {
    margin: 4.25rem auto 0;
  }
}