.lead {
  display: block;

  width: var(--width-content);
  max-width: 100%;
  margin: 4rem auto 0;

  font-size: 2rem;
  font-weight: 400;
  line-height: 1.25;

  color: var(--color-text);

  h2 {
    margin: 0 auto 2.5rem auto;
    text-align: center;

    font-size: 5.626rem;
    font-weight: 700;
    font-family: var(--font-caption);


    text-transform: uppercase;

    @media screen and (max-width: 767px) {
      font-size: 4rem;
    }

    @media screen and (max-width: 479px) {
      font-size: 3.25rem;
    }

    @media screen and (max-width: 359px) {
      font-size: 2.5rem;
    }
  }

  p {
    margin: 0 0 2rem;
  }

  a {
    color: var(--color-links);
    transition: color 0.25s;

    &:hover {
      color: var(--color-accent);
    }
  }

  @media screen and (max-width: 767px) {
    font-size: 1.75rem;
    line-height: 1.375;
  }

  @media screen and (max-width: 479px) {
    font-size: 1.5rem;
    line-height: 1.5;
  }

  @media screen and (max-width: 359px) {
    font-size: 1.25rem;
  }
}