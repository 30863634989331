.sharing {
  display: flex;
  align-items: center;

  margin: 0;
  padding: 0;

  a {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 2.75rem;
    height: 2.75rem;
    margin-left: 0.75rem;
    padding: 0.25rem;

    border-radius: 50%;

    &:first-child {
      margin-left: 0;
    }
  }

  svg {
    width: 1.25rem;
    height: auto;

    fill: var(--color-text);
  }
}