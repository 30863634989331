.notfound {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  position: relative;
  z-index: 2;

  width: var(--width-site);
  max-width: 100%;
  margin: 0 auto;
  padding: 1.5rem 0 2.5rem;

  .notfound-header {
    width: 100%;
    max-width: calc(100% - 3rem);
    margin: 0 auto 1rem;

    @media screen and (max-width: 1023px) {
      width: var(--width-content);
      margin-bottom: 2rem;
    }
  }

  .notfound-tshirt {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    position: relative;

    width: var(--width-content);
    max-width: 100%;
    margin: 0 auto 1rem;
    overflow: hidden;

    figure {
      display: block;
      position: relative;
      margin: 1rem 0 2rem;

      transform: translateX(1vw);

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;

        width: 100%;
        height: 100%;

        background-image: url('/images/decors/ellipse.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;

        transform: rotate(-20deg) scale(1.5);
      }
    }

    button {
      position: relative;
      z-index: 2;
    }
  }
}