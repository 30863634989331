.motivation {
  display: block;
  position: relative;
  z-index: 2;

  width: 100%;
  margin: 3.25rem auto 0;

  overflow: hidden;

  background-image: var(--gradient-green), var(--gradient-purple);

  background-position: calc(50% - 4rem) calc(100% - 4rem), calc(50% + 4rem) calc(100% - 10rem);
  background-size: 30rem 30rem, 30rem 30rem;
  background-repeat: no-repeat;

  .motivation-start {
    max-width: var(--width-max);
  }

  .motivation-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;

    width: var(--width-site);
    max-width: var(--width-max);
    margin: 0 auto;
    padding: 0;

    li {
      display: flex;
      align-items: center;
      flex: 1 1 100%;

      padding: 1.875rem 1.25rem;

      font-size: 1.5rem;
      font-weight: 500;
      line-height: 1.25;

      color: var(--color-black);
      background-image: linear-gradient(to right, var(--color-links), var(--color-accent));

      border-radius: 2rem;

      &::before {
        content: '02';

        display: block;
        margin-right: 0.875rem;

        font-size: 6.25rem;
        font-weight: 700;
        font-family: var(--font-caption);
        line-height: 1;

        transform: translateY(-0.075em);

        @media screen and (max-width: 479px) {
          font-size: 6rem;
        }

        @media screen and (max-width: 359px) {
          font-size: 4.25rem;
        }
      }

      @media screen and (max-width: 1023px) {
        padding-left: 10%;
      }

      @media screen and (max-width: 767px) {
        padding-left: 4rem;
      }

      @media screen and (max-width: 479px) {
        padding-left: 1.25rem;
        font-size: 1.375rem;
      }

      @media screen and (max-width: 359px) {
        padding-left: 1.25rem;
        font-size: 1.25rem;
      }

      @media screen and (max-width: 479px) {
        padding-left: 1.25rem;
      }
    }

    li:first-child::before {
      content: '01';
    }

    li:last-child::before {
      content: '03';
    }

    @media screen and (max-width: 1023px) {
      display: flex;
      flex-flow: row wrap;
      width: var(--width-content);
    }
  }

  .motivation-shirt {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    width: 36rem;
    height: 36rem;
    max-width: 100%;
    margin: 3.5rem auto;
    overflow: hidden;

    img {
      display: block;
      position: absolute;
    }

    img[data-image='rotation'] {
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      animation: rotation linear 60s infinite;
    }

    img[data-image='shirt'] {
      top: 50%;
      left: 50%;

      width: 55%;
      height: auto;

      transform: translate(-50%, -50%);
    }

    @media screen and (max-width: 767px) {
      width: 75vw;
      height: 75vw;
    }

    @media screen and (max-width: 479px) {
      width: 100vw;
      height: 100vw;
      margin: 8.75rem auto;

      transform: scale(1.5);
    }
  }

  @media screen and (max-width: 479px) {
    margin: 0 auto -2rem;
  }
}