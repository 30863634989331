.swiper {
  position: relative;

  .swiper-wrapper {
    align-items: center;
  }

  .swiper-slide {
    position: relative;

    width: 20rem;
    max-width: calc(100% - 3rem);
    margin: 0 1.25rem;

    @media (hover: none) {
      transform: none;
    }

    @media screen and (max-width: 1023px) {
      margin: 0 1.25rem;
    }

    @media screen and (max-width: 767px) {
      margin: 0 0.75rem;
    }

    @media screen and (max-width: 479px) {
      margin: 0 0.5rem;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    position: absolute;
    top: 50%;
    z-index: 10;

    width: 4rem;
    height: 4rem;
    margin-top: -2rem;

    background-color: var(--color-text);
    border-radius: 50%;

    transition: background-color 0.25s;

    svg {
      width: 1.25rem;
      height: 1.25rem;

      fill: var(-color-text);
    }

    &:hover {
      background-color: var(--color-accent);
    }

    @media screen and (max-width: 1023px) {
      top: auto;
      bottom: 0;

      width: 5rem;
      height: 5rem;
    }

    @media screen and (max-width: 479px) {
      width: 4rem;
      height: 4rem;
    }
  }

  .swiper-button-prev {
    left: 2.5rem;

    svg {
      transform: rotate(180deg);
    }

    @media screen and (max-width: 1023px) {
      left: calc(50% - 12rem);
    }

    @media screen and (max-width: 479px) {
      left: calc(50% - 8rem);
    }
  }

  .swiper-button-next {
    right: 2.5rem;

    @media screen and (max-width: 1023px) {
      right: calc(50% - 12rem);
    }

    @media screen and (max-width: 479px) {
      right: calc(50% - 8rem);
    }
  }

  .swiper-button-disabled {
    display: none;
  }
}