html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  position: relative;

  margin: 0;
  padding: 0;

  font: 400 16px/1.5 var(--font-general);
  letter-spacing: 0.02em;

  color: var(--color-text);
  background-color: var(--color-background);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.is-loading {
    animation: fade-out 0.25s forwards;
  }
}

button {
  font: inherit;
}

input, textarea {
  font: inherit;
}

img {
  max-width: 100%;
  height: auto;
  transition: opacity 0.25s;

  &[data-loaded="hidden"] {
    opacity: 0;
  }

  &[data-loaded="visible"] {
    opacity: 1;
  }
}

a {
  text-decoration: none;
}

[data-parallax] {
  opacity: 0;

  &[data-parallax="visible"] {
    animation: fade-in 0.5s forwards;
  }
}