.summary {
  display: block;
  position: relative;
  z-index: 2;

  width: var(--width-content);
  max-width: var(--width-max);
  margin: 4.5rem auto 0;

  font-size: 2.5rem;
  font-weight: 400;
  line-height: 1.25;

  color: var(--color-text);

  p {
    margin: 0 0 2rem;

    &:last-child {
      margin: 0;
    }
  }

  a {
    color: var(--color-links);
    transition: color 0.25s;

    &:hover {
      color: var(--color-accent);
    }
  }

  @media screen and (max-width: 767px) {
    margin-top: 3.25rem;
    margin-bottom: 0;
    font-size: 1.75rem;
    line-height: 1.375;
  }

  @media screen and (max-width: 479px) {
    margin-top: 2.5rem;
    font-size: 1.5rem;
    line-height: 1.5;
  }

  @media screen and (max-width: 359px) {
    font-size: 1.25rem;
  }
}