.footer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  position: relative;
  z-index: 2;

  width: var(--width-wide);
  max-width: calc(100% - 3rem);

  margin: 1rem auto 1.5rem;
  padding: 0;

  font-weight: 300;

  h4 {
    margin: 0 0 1.5rem;

    font-size: 1.25rem;
    font-weight: 500;
  }

  p {
    margin: 0 0 1.5rem;
  }

  a {
    color: var(--color-links);
    transition: color 0.25s;

    &:hover {
      color: var(--color-accent);
    }
  }

  nav {
    margin: 0 0 1.5rem;

    & > a {
      border: solid 1px var(--color-text);
      transition: 0.25s;
    }

    & > a:hover {
      background-color: var(--color-hover);
      border-color: var(--color-hover);

      svg {
        fill: var(--color-black);
      }
    }
  }

  .footer-description {
    display: flex;
    flex-flow: column nowrap;

    width: 28rem;
    max-width: 100%;

    @media screen and (max-width: 1023px) {
      align-items: center;
      text-align: center;
    }
  }

  .footer-social {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-end;

    width: 28rem;
    max-width: 100%;

    text-align: right;

    p:last-child {
      margin-top: auto;
    }

    @media screen and (max-width: 1023px) {
      align-items: center;
      text-align: center;
    }
  }

  @media screen and (max-width: 1023px) {
    flex-flow: column nowrap;
    align-items: center;
  }
}