.upper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  min-height: 3rem;
  padding: 0;

  .upper-logo {
    display: block;
    position: relative;

    width: 8.5rem;
    height: auto;
    max-width: 100%;

    span {
      display: block;
      position: absolute;
      bottom: 100%;

      width: 100%;
      opacity: 0.5;

      font-size: 0.75rem;
      font-weight: 300;

      white-space: nowrap;

      color: var(--color-text);

      @media screen and (max-width: 767px) {
        font-size: 0.675rem;
      }

      @media screen and (max-width: 479px) {
        font-size: 0.5375rem;
      }
    }

    svg {
      display: block;

      width: 100%;
      height: auto;

      fill: var(--color-text);
    }

    @media screen and (max-width: 767px) {
      width: 7.5rem;
    }

    @media screen and (max-width: 479px) {
      width: 6.25rem;
    }
  }

  .upper-text {
    display: block;

    button {
      height: 3rem;
      padding: 0 1.75rem;
    }

    svg {
      margin-right: 1rem;
      fill: var(--color-black);
      transform: rotate(180deg);
    }
  }

  .upper-chevron {
    display: block;

    button {
      width: 3.125rem;
      height: 3.125rem;
      padding: 0;

      @media screen and (max-width: 479px) {
        width: 2.75rem;
        height: 2.75rem;
      }
    }

    svg {
      fill: var(--color-black);
      transform: rotate(180deg) translateX(0.125rem);
    }
  }

  .upper-lang {
    display: flex;
    align-items: center;

    button {
      display: block;
      cursor: pointer;

      margin: 0 0.5rem;
      padding: 0.25rem 0.5rem 0.125rem;

      font-size: 1rem;
      font-weight: 300;
      line-height: 1;

      color: var(--color-text);
      background-color: transparent;
      border: none;

      transition: color 0.25s;

      &[data-current] {
        pointer-events: none;
        font-weight: 500;
      }

      &:hover {
        color: var(--color-accent);
      }

      @media screen and (max-width: 767px) {
        margin: 0 0.25rem;
        font-size: 0.875rem;
      }
    }

    span {
      height: 1rem;
      border-right: solid 1.5px var(--color-text);
    }
  }
}